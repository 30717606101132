import React from 'react';
import ErrorCardStyles from './styles';
import Text from '../Text';
import ErrorIcon from '../Icons/Singletone/ErrorIcon';
import Display from '../Display';

type ErrorCardProps = { warning?: boolean };

const ErrorCard: React.FC<ErrorCardProps> = ({ children, warning }) => (
  <ErrorCardStyles.Card warning={warning}>
    <Display display="flex" gapHorizontal="rem1">
      <ErrorIcon color="error" />
      <Text.Paragraph fontSize="text-base" color="error">
        {children}
      </Text.Paragraph>
    </Display>
  </ErrorCardStyles.Card>
);

export default ErrorCard;
