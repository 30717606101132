import { useCallback } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getResponseData = async <T = any>(response: Response): Promise<T | undefined> => {
  try {
    return await response.json();
  } catch {
    try {
      const val = await response.text();
      if (val) {
        return (val as unknown) as T;
      }
    } catch {
      // Do nothing since it'll return undefined at the end
    }
  }

  return undefined;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchApi = async <T = any>(input: RequestInfo, init?: RequestInit): Promise<T | undefined> => {
  try {
    const response = await fetch(input, init);

    if (response.ok) {
      return await getResponseData(response);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const error = new Error(response.statusText) as any;
      error.response = response;
      error.data = await getResponseData(response);
      throw error;
    }
  } catch (error) {
    if (!error.data) {
      error.data = { message: error.message };
    }
    throw error;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useFetchApi = <BodyVariables, Result = string>(input: RequestInfo, init?: RequestInit | undefined) =>
  useCallback(
    (body?: BodyVariables) => fetchApi<Result>(input, { ...init, body: body ? JSON.stringify(body) : undefined }),
    // TODO: why does it complain here but not in other projects
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [input, init]
  );

export const fetcher = (...args: [RequestInfo, RequestInit | undefined]) => fetchApi(...args);
