import manipulateColor from 'color';
import styled, { css } from 'styled-components';
import { AppTheme } from '../../../styles/theme';
import { TextCssProps, textCss } from '../Text';
import { borderRadiusCss, BorderRadiusProps } from '../Helpers/borderRadius';

export type ButtonProps = TextCssProps &
  BorderRadiusProps & {
    variant?: 'light' | 'solid' | 'secondary' | 'dark' | 'colored' | 'grey' | 'white' | 'allWhite' | 'whiteOnPrimary'; // White == default
    color?: keyof AppTheme['colors'];
    fullWidth?: boolean;
    halfWidth?: boolean;
    fontSize?: string;
    noHoverEffect?: boolean;
    withIcon?: boolean;
  };

const defaultButton = (color: ButtonProps['color']) => css<ButtonProps>`
  background-color: ${(props) => (color ? props.theme.colors[color] : props.theme.colors.primary)};
  color: ${(props) => props.theme.colors.light};
  border: 2px solid ${(props) => props.theme.colors.primary};
  border-radius: 4px;

  transition: color 90ms ease-in-out 0s, background-color 90ms ease-in-out 0s, border-color 90ms ease-in-out 0s,
    filter 90ms ease-in-out 0s;

  ${(props) =>
    props.withIcon
      ? `display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        svg { 
          margin-left: ${props.theme.spacing.px16}; 
        }

        &:hover {
          svg { fill: ${props.theme.colors.primary}; }
        }
      `
      : ``}

  &:hover {
    background-color: ${(props) => manipulateColor(props.theme.colors.primary).alpha(0.2).string()};
    color: ${(props) => props.theme.colors.primary};
  }

  &:active {
    transition: none;
  }
`;

const solidButton = (color: ButtonProps['color']) => css<ButtonProps>`
  background-color: ${(props) => (color ? props.theme.colors[color] : props.theme.colors.primary)};
  color: ${(props) => props.theme.colors.light};

  transition: color 90ms ease-in-out 0s, background-color 90ms ease-in-out 0s, border-color 90ms ease-in-out 0s,
    filter 90ms ease-in-out 0s;

  ${(props) =>
    props.withIcon &&
    css`
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      svg {
        margin-left: ${props.theme.spacing.px16};
      }

      &:hover {
        svg {
          fill: ${color !== props.theme.colors.light ? props.theme.colors.light : props.theme.colors.primary};
        }
      }
    `}

  &:hover {
    background-color: ${(props) =>
      color ? props.theme.colors.primary : color ? props.theme.colors[color] : props.theme.colors.secondary};
    color: ${(props) => (color !== props.theme.colors.light ? props.theme.colors.light : props.theme.colors.primary)};
  }

  &:active {
    transition: none;
  }
`;

const outlineButton = (color: string) => css`
  background-color: transparent;
  color: ${color};
  border: 2px solid ${color};
  border-radius: 4px;
  transition: filter 90ms ease-in-out 0s;

  &:hover {
    background-color: ${manipulateColor(color).alpha(0.1).string()};
  }

  &:active {
    background-color: ${manipulateColor(color).alpha(0.15).string()};
    transition: none;
  }
`;

const lightButton = css`
  background-color: ${(props) => props.theme.colors.light};
  color: ${(props) => props.theme.colors.darkestBorder};
  border-radius: 4px;
  border: 2px solid rgba(0, 0, 0, 0);

  transition: color 90ms ease-in-out 0s, background-color 90ms ease-in-out 0s, border-color 90ms ease-in-out 0s,
    filter 90ms ease-in-out 0s;

  &:hover {
    background-color: ${(props) => props.theme.colors.primary};
    border: 2px solid ${(props) => props.theme.colors.light};
    color: ${(props) => props.theme.colors.light};
  }

  &:active {
    transition: none;
  }
`;

const secondaryButton = css`
  background-color: ${(props) => props.theme.colors.secondary};
  color: ${(props) => props.theme.colors.light};
  border: 2px solid ${(props) => props.theme.colors.secondary};
  border-radius: 4px;

  transition: color 90ms ease-in-out 0s, background-color 90ms ease-in-out 0s, border-color 90ms ease-in-out 0s,
    filter 90ms ease-in-out 0s;

  &:hover {
    background-color: ${(props) => manipulateColor(props.theme.colors.secondary).alpha(0.2).string()};
    border-color: ${(props) => props.theme.colors.secondary};
    color: ${(props) => props.theme.colors.secondary};
  }

  &:active {
    transition: none;
  }
`;

const darkButton = css`
  ${(props) => outlineButton(props.theme.colors.primary)}
`;

const greyButton = css`
  ${(props) => outlineButton(props.theme.colors.primary)}
  transition: color 90ms ease-in-out 0s, background-color 90ms ease-in-out 0s, border-color 90ms ease-in-out 0s,
    filter 90ms ease-in-out 0s;
  color: ${(props) => props.theme.colors.primary};

  &:hover {
    background-color: ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.primary};
    text-decoration: underline;
  }

  &:active {
    background-color: ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.primary};
    text-decoration: underline;
    filter: brightness(0.75);
    transition: none;
  }
`;

const allWhiteButton = css`
  ${(props) => outlineButton(props.theme.colors.primary)}
  background-color: ${(props) => props.theme.colors.primary};
  transition: color 90ms ease-in-out 0s, background-color 90ms ease-in-out 0s, border-color 90ms ease-in-out 0s,
    filter 90ms ease-in-out 0s;
  color: ${(props) => props.theme.colors.primary};

  &:hover {
    background-color: ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.primary};
    text-decoration: underline;
  }

  &:active {
    background-color: ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.primary};
    text-decoration: underline;
    filter: brightness(0.75);
    transition: none;
  }
`;

const whiteOnPrimary = css`
  ${(props) => outlineButton(props.theme.colors.primary)}
  background-color: ${(props) => props.theme.colors.primary};
  transition: color 90ms ease-in-out 0s, background-color 90ms ease-in-out 0s, border-color 90ms ease-in-out 0s,
    filter 90ms ease-in-out 0s;
  color: ${(props) => props.theme.colors.primary};

  &:hover {
    background-color: ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.primary};
    text-decoration: underline;
  }

  &:active {
    background-color: ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.primary};
    text-decoration: underline;
    filter: brightness(0.75);
    transition: none;
  }
`;

const coloredButton = (color: ButtonProps['color']) => css`
  ${(props) => outlineButton(color ? (props.theme.colors[color] as string) : props.theme.colors.primary)}
`;

const getButtonVariant = (variant: ButtonProps['variant'], color: ButtonProps['color']) => {
  switch (variant) {
    case 'light':
      return lightButton;
    case 'solid':
      return solidButton(color);
    case 'secondary':
      return secondaryButton;
    case 'dark':
      return darkButton;
    case 'colored':
      return coloredButton(color);
    case 'grey':
      return greyButton;
    case 'allWhite':
      return allWhiteButton;
    case 'whiteOnPrimary':
      return whiteOnPrimary;
    default:
      return defaultButton(color);
  }
};

const buttonStyles = css<ButtonProps>`
  cursor: pointer;

  /* Sizing and positioning */
  padding: 0 ${(props) => props.theme.spacing.px24};
  outline: 0;

  &:disabled {
    cursor: default;
    opacity: 0.3;
  }

  ${(props) => getButtonVariant(props.variant, props.color)};

  /* Text */
  letter-spacing: -0.5px;
  font-weight: 600;
  font-size: 1rem;
  /* Override text with props */
  ${textCss}
  ${borderRadiusCss}
`;

export default {
  Small: styled.button`
    ${buttonStyles};
    height: 44px;
  `,
  Medium: styled.button`
    ${buttonStyles};
    height: 68px;
  `,
  Large: styled.button`
    ${buttonStyles};
    height: 78px;
  `,
  xLarge: styled.button`
    ${buttonStyles};
    height: 86px;
  `,
  xxLarge: styled.button`
    ${buttonStyles};
    height: 98px;
  `,
  Default: styled.button`
    ${buttonStyles};
  `,
  Invisible: styled.button`
    /* The invisible button used when we want to keep accessibility (clickable things are buttons)
    but want to make it look like something else. Or just have a clickable link/text */
    cursor: pointer;
    padding: 0;
    outline: 0;
    background: transparent;

    &:disabled {
      cursor: default;
      opacity: 0.3;
    }

    /* Override text with props */
    ${textCss}
  `,
  HoverIcon: styled.button<TextCssProps & { hoverAmount?: number; activeAmount?: number }>`
    /* The invisible button used when we want to keep accessibility (clickable things are buttons)
    but want to make it look like something else. Or just have a clickable link/text */
    cursor: pointer;
    padding: 0;
    outline: 0;
    background: transparent;

    &:disabled {
      cursor: default;
      opacity: 0.3;
    }

    /* Override text with props */
    ${textCss}

    > * {
      transition: transform 100ms ease-out;
    }

    &:hover {
      > * {
        transform: ${(props) => css`scale(${props.hoverAmount || 1.2})`};
      }
    }

    &:active {
      > * {
        transform: ${(props) => css`scale(${props.activeAmount || 1})`};
        transition: transform 20ms ease-in;
      }
    }
  `
};
