/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo } from 'react';
import useSWR from 'swr';
import { fetcher, useFetchApi } from '../../utils/fetchUtils';
import { useRouter } from 'next/router';
import { AuthUser } from 'src/api/lib/auth';

export const useLogin = <Body = Record<string, any>>() =>
  useFetchApi<Body, AuthUser>('/api/login', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  });

export const useSignup = <Body = Record<string, any>>() =>
  useFetchApi<Body, AuthUser>('/api/signup', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  });

export const useLogout = () =>
  useFetchApi('/api/logout', {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  });

export const usePasswordConfirm = <Body = Record<string, any>>(locale?: string) =>
  useFetchApi<Body>(`/api/${locale || 'en'}/passwordConfirm`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  });

export const usePasswordEmail = <Body = Record<string, any>>(locale?: string) =>
  useFetchApi<Body>(`/api/${locale || 'en'}/passwordEmail`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  });

type UserHookOptions = {
  redirectIfFound?: {
    as?: string;
    url: string;
  };
  required?: boolean;
  skipFetch?: boolean;
  initialData?: AuthUser;
};

export const useUser = (options?: UserHookOptions) => {
  // Stale while revalidate the user
  const { data, error, mutate: setUser } = useSWR<AuthUser>(options?.skipFetch ? null : '/api/user', fetcher, {
    initialData: options?.initialData
  });

  const accessToken = useMemo(() => data?.accessToken, [data]);
  const user = useMemo(() => (!error ? data?.user : undefined), [data, error]);
  const isLoading = useMemo(() => !error && !data, [error, data]);

  const router = useRouter();

  useEffect(() => {
    const errorMessage: string | undefined = error?.data?.error?.message;

    const isAuthError =
      // In dev it won't throw "unauthorized"
      errorMessage?.includes('Invalid session payload') || errorMessage?.includes('Unauthorized');

    // If this is a required page, and we finished loading, but still don't have the user
    if (options?.required && !user && !isLoading && !!isAuthError) {
      router.push('/', `/`);
    }
    if (options?.redirectIfFound && user && !isLoading && !isAuthError) {
      router.push(options.redirectIfFound.url, options.redirectIfFound.as);
    }
  }, [user, error, router, isLoading, options]);

  return { accessToken, user, isLoading, error, setUser };
};
