/* eslint-disable @typescript-eslint/no-explicit-any */

export const getLocaleIdFromFetchError = async (error?: Error | any) => {
  // If we have a graphql error
  // if (error?.data?.error?.graphQLErrors || error?.data?.error?.networkError) {
  //   // Use existing parser
  //   return getLocaleIdFromGraphqlError(error?.data?.error?.graphQLErrors, error?.data?.error?.networkError);
  // } else
  if (error?.data?.message && error?.data?.message.toUpperCase().includes('ECONNREFUSED')) {
    return 'serverErrors.connectionRefused';
  } else if (error?.data?.code) {
    return `serverErrors.${error.data.code}`;
  } else if (error?.data?.name) {
    return `serverErrors.${error.data.name}`;
  } else if (error?.data?.message) {
    return `Not handled: ${error.data.message}`;
  } else if (error?.data?.error?.code) {
    return `serverErrors.${error.data.error.code}`;
  } else if (error?.data?.error?.name) {
    return `serverErrors.${error.data.error.name}`;
  } else if (error?.data?.error?.message) {
    return `Not handled: ${error.data.error.message}`;
  } else if (error?.response?.text) {
    // If there's the "text" function(from fetch)
    // Try to call the function
    const message = (await error.response.text()) as string | undefined;

    // If the message is unauthorized
    if (message && message.toLowerCase() === 'unauthorized') {
      return 'serverErrors.unauthorized';
    } else if (message && message.toUpperCase().includes('ECONNREFUSED')) {
      // If there's no internet
      return 'serverErrors.connectionRefused';
    } else {
      // If the error is not handled yet
      return message ? `Not handled: ${message}` : 'serverErrors.unknownError';
    }
  } else if (error?.message && error.message.toLowerCase() === 'failed to fetch') {
    // If there's no internet
    return 'serverErrors.connectionRefused';
  } else {
    // If the error is not handled yet
    return error?.message ? `Not handled: ${error.message}` : 'serverErrors.unknownError';
  }
};
