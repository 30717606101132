import React, { useMemo } from 'react';
import IconStyles, { IconProps } from '../styles';
import { AppTheme } from '../../../../styles/theme';
import { useTheme } from 'styled-components';

type ErrorIconProps = IconProps & {
  color?: keyof AppTheme['colors'];
};

const ErrorIcon: React.FC<ErrorIconProps> = ({ color, ...props }) => {
  const theme = useTheme();
  const fill = useMemo(() => (color ? theme.colors[color] : '#FFF'), [color, theme]);
  return (
    <IconStyles.IconContainer {...props}>
      <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <path d="M-1-1h24v24H-1z" />
          <path
            d="M11 0c6.075 0 11 4.925 11 11s-4.925 11-11 11S0 17.075 0 11 4.925 0 11 0zm0 2a9 9 0 100 18 9 9 0 000-18zm0 12c.513 0 .936.383.993.883L12 15a.997.997 0 01-.883.993L11 16c-.552 0-1-.444-1-1 0-.513.383-.936.883-.993L11 14zm0-8c.552 0 1 .438 1 1.003v4.994a1 1 0 01-.883.996L11 13c-.552 0-1-.438-1-1.003V7.003a1 1 0 01.883-.996L11 6z"
            fill={fill}
          />
        </g>
      </svg>
    </IconStyles.IconContainer>
  );
};

export default ErrorIcon;
