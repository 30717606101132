import { css } from 'styled-components';
import { responsiveMediaCss } from '../../../utils/mediaQueryUtils';
import { Globals, SelfPosition } from 'csstype';

// Equivalent of csstype.AlignSelfProperty but without "string".
// Because string forces it to always be string, since it'll get the less specific type
type ValueType = Globals | SelfPosition | 'auto' | 'baseline' | 'normal' | 'stretch';

export type AlignSelfProps = {
  alignSelf?: ValueType;
  ['sm-alignSelf']?: ValueType;
  ['md-alignSelf']?: ValueType;
  ['lg-alignSelf']?: ValueType;
  ['xl-alignSelf']?: ValueType;
};

const getAlignSelf = (alignSelf: ValueType) => css`
  align-self: ${alignSelf};
`;

export const alignSelfCss = css<AlignSelfProps>`
  ${(props) => props.alignSelf && getAlignSelf(props.alignSelf)}
  ${(props) => props['sm-alignSelf'] && responsiveMediaCss(getAlignSelf(props['sm-alignSelf']), 'mobile')}
  ${(props) => props['md-alignSelf'] && responsiveMediaCss(getAlignSelf(props['md-alignSelf']), 'tablet')}
  ${(props) => props['lg-alignSelf'] && responsiveMediaCss(getAlignSelf(props['lg-alignSelf']), 'laptop')}
  ${(props) => props['xl-alignSelf'] && responsiveMediaCss(getAlignSelf(props['xl-alignSelf']), 'desktop')}
`;
