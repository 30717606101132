import styled, { css } from 'styled-components';

export default {
  Card: styled.div<{ warning?: boolean }>`
    border-radius: 4px;
    width: 100%;
    padding: ${(props) => props.theme.spacing.px24};

    ${(props) =>
      props.warning
        ? css`
            background: ${props.theme.colors.border};
          `
        : css`
            background: ${(props) => props.theme.colors.errorBackground};
            border: 2px solid ${(props) => props.theme.colors.error};
          `};
  `
};
