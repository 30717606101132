import { css } from 'styled-components';
import { responsiveMediaCss } from '../../../utils/mediaQueryUtils';
import { Globals, SelfPosition } from 'csstype';

// Equivalent of csstype.AlignItemsProperty but without "string".
// Because string forces it to always be string, since it'll get the less specific type
type ValueType = Globals | SelfPosition | 'baseline' | 'normal' | 'stretch';

export type AlignItemsProps = {
  alignItems?: ValueType;
  ['sm-alignItems']?: ValueType;
  ['md-alignItems']?: ValueType;
  ['lg-alignItems']?: ValueType;
  ['xl-alignItems']?: ValueType;
};

const getAlignItems = (alignItems: ValueType) => css`
  align-items: ${alignItems};
`;

export const alignItemsCss = css<AlignItemsProps>`
  ${(props) => props.alignItems && getAlignItems(props.alignItems)}
  ${(props) => props['sm-alignItems'] && responsiveMediaCss(getAlignItems(props['sm-alignItems']), 'mobile')}
  ${(props) => props['md-alignItems'] && responsiveMediaCss(getAlignItems(props['md-alignItems']), 'tablet')}
  ${(props) => props['lg-alignItems'] && responsiveMediaCss(getAlignItems(props['lg-alignItems']), 'laptop')}
  ${(props) => props['xl-alignItems'] && responsiveMediaCss(getAlignItems(props['xl-alignItems']), 'desktop')}
`;
