import { Globals } from 'csstype';
import { css } from 'styled-components';
import { responsiveMediaCss } from '../../../utils/mediaQueryUtils';

// Equivalent of csstype.CursorProperty but without "string".
// Because string forces it to always be string, since it'll get the less specific type
type ValueType =
  | Globals
  | '-moz-grab'
  | '-webkit-grab'
  | 'alias'
  | 'all-scroll'
  | 'auto'
  | 'cell'
  | 'col-resize'
  | 'context-menu'
  | 'copy'
  | 'crosshair'
  | 'default'
  | 'e-resize'
  | 'ew-resize'
  | 'grab'
  | 'grabbing'
  | 'help'
  | 'move'
  | 'n-resize'
  | 'ne-resize'
  | 'nesw-resize'
  | 'no-drop'
  | 'none'
  | 'not-allowed'
  | 'ns-resize'
  | 'nw-resize'
  | 'nwse-resize'
  | 'pointer'
  | 'progress'
  | 'row-resize'
  | 's-resize'
  | 'se-resize'
  | 'sw-resize'
  | 'text'
  | 'vertical-text'
  | 'w-resize'
  | 'wait'
  | 'zoom-in'
  | 'zoom-out';

export type CursorProps = {
  cursor?: ValueType;
  ['sm-cursor']?: ValueType;
  ['md-cursor']?: ValueType;
  ['lg-cursor']?: ValueType;
  ['xl-cursor']?: ValueType;
};

const getCursor = (cursor: ValueType) => css`
  cursor: ${cursor};
`;

export const cursorCss = css<CursorProps>`
  ${(props) => props.cursor && getCursor(props.cursor)}
  ${(props) => props['sm-cursor'] && responsiveMediaCss(getCursor(props['sm-cursor']), 'mobile')}
  ${(props) => props['md-cursor'] && responsiveMediaCss(getCursor(props['md-cursor']), 'tablet')}
  ${(props) => props['lg-cursor'] && responsiveMediaCss(getCursor(props['lg-cursor']), 'laptop')}
  ${(props) => props['xl-cursor'] && responsiveMediaCss(getCursor(props['xl-cursor']), 'desktop')}
`;
