import * as CSS from 'csstype';
import styled, { css, keyframes, Keyframes } from 'styled-components';
import { SpacingEnum } from '../../../styles/theme';

export const fadeUp = keyframes`
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const fadeIn = keyframes`
  100% {
    opacity: 1;
  }
`;

const centered = css`
  display: flex;
  align-content: center;
  justify-content: center;
`;

type AnimationProps = {
  centered?: boolean;
  seconds?: number;
  delaySeconds?: number;
  repeat?: boolean;
  easing?: CSS.AnimationTimingFunctionProperty;
};

export const animation = (keyframes: Keyframes) => css<AnimationProps>`
  height: 100%;
  width: 100%;
  animation: ${keyframes}
    ${(props) =>
      props.seconds
        ? css`
            ${props.seconds}s
          `
        : css`0.5s`}
    ${(props) => (props.easing ? props.easing : css`ease-out`)}
    ${(props) =>
      props.delaySeconds
        ? css`
            ${props.delaySeconds}s
          `
        : css`0s`}
    both;
  ${(props) => props.centered && centered};
  ${(props) =>
    props.repeat &&
    css`
      animation-iteration-count: infinite;
    `};
`;

export default {
  FadeUp: styled.div<
    AnimationProps & {
      amount?: SpacingEnum;
    }
  >`
    opacity: 0;
    transform: ${(props) =>
      css`translateY(${props.amount ? props.theme.spacing[props.amount] : props.theme.spacing.px16})`};
    ${animation(fadeUp)}
  `,
  FadeIn: styled.div<AnimationProps>`
    opacity: 0;
    ${animation(fadeIn)}
  `,
  FadeDown: styled.div<
    AnimationProps & {
      amount?: SpacingEnum;
    }
  >`
    opacity: 0;
    transform: ${(props) =>
      css`translateY(-${props.amount ? props.theme.spacing[props.amount] : props.theme.spacing.px16})`};
    ${animation(fadeUp)}
  `,
  ScaleHover: styled.div<{ hoverAmount?: number; activeAmount?: number; inline?: boolean }>`
    > * {
      transition: transform 100ms ease-out;
    }

    &:hover {
      > * {
        transform: ${(props) => css`scale(${props.hoverAmount || 1.2})`};
      }
    }

    &:active {
      > * {
        transform: ${(props) => css`scale(${props.activeAmount || 1})`};
        transition: transform 20ms ease-in;
      }
    }

    ${(props) =>
      props.inline &&
      css`
        display: inline-block;
      `}
  `
};
