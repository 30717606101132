import { useField } from 'formik';
import React, { useMemo } from 'react';
import { TextInput } from '@perimetre/ui';

type FormikTextFieldProps = React.InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  label?: string;
};

const FormikTextField: React.FC<FormikTextFieldProps> = ({ name, ...props }) => {
  const [field, meta] = useField(name);
  const error = useMemo(() => (meta.touched ? meta.error : undefined), [meta]);

  return (
    <TextInput
      {...props}
      {...field}
      onChange={(e) => {
        if (props.onChange) props.onChange(e);
        field.onChange(e);
      }}
      error={error}
      id={`TextInput-${name}`}
    />
  );
};

export default FormikTextField;
