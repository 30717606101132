import styled from 'styled-components';
import { cursorCss, CursorProps } from '../Helpers/cursor';
import { sizingCss, SizingProps } from '../Helpers/sizing';

export type IconProps = SizingProps & CursorProps;

export default {
  IconContainer: styled.div<IconProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    ${cursorCss}

    /* The first child and ONLY the first child */
    > * {
      /* Allow us to size the svg */
      ${sizingCss}
    }
  `
};
