/* eslint-disable no-template-curly-in-string */
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import * as yup from 'yup';

// Ref: https://github.com/jquense/yup/blob/master/src/locale.js
import printValue from 'yup/lib/util/printValue';
import { unflattennedLocales } from '../../../locales';
import { useLocale } from '../../components/Providers/localeProvider';

export const useLocalizedYup = () => {
  const locale = useLocale();
  const intl = useIntl();

  return useMemo(() => {
    // If there's yupValidation locale for the current locale
    if (locale && unflattennedLocales[locale] && unflattennedLocales[locale].yupValidation) {
      // Get the messages
      const messages = { ...unflattennedLocales[locale].yupValidation };

      // Delete messages used for actual formatting
      delete messages.mixed.notType;
      delete messages.mixed.notTypeCast;
      delete messages.mixed.notTypeNull;

      // Overwrite the mixed object
      const mixed: yup.LocaleObject['mixed'] = {
        ...messages.mixed, // Get the default mixed object.
        // Add the type function
        notType: ({ path, type, value, originalValue }) => {
          // Get the cast message if any
          let isCast = '';
          if (originalValue != null && originalValue !== value) {
            isCast = intl.formatMessage(
              { id: 'yupValidation.mixed.notTypeCast' },
              { value: printValue ? printValue(originalValue, true) : originalValue }
            );
          }

          // Get the null message if any
          let isNull = '';
          if (value === null) {
            isNull = intl.formatMessage({ id: 'yupValidation.mixed.notTypeNull' });
          }

          // Get the final message
          return intl.formatMessage(
            { id: 'yupValidation.mixed.notType' },
            { value: printValue ? printValue(value, true) : value, isCast, isNull, path, type }
          );
        }
      };

      yup.setLocale({
        ...messages, // Localize everything
        mixed // Add the overridden version
      });
    }
    return yup;
  }, [locale, intl]);
};
