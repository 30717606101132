import styled, { keyframes } from 'styled-components';
import { AppTheme } from '../../../styles/theme';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

export type SpinnerProps = {
  color?: keyof AppTheme['colors'];
  size?: string;
  thickness?: string;
};

export default {
  Default: styled.div<SpinnerProps>`
    content: '';
    box-sizing: border-box;
    width: ${(props) => props.size || '1em'};
    height: ${(props) => props.size || '1em'};
    border-radius: 50%;
    border: 2px solid ${(props) => (props.color ? props.theme.colors[props.color] : 'white')};
    border-width: ${(props) => props.thickness || '2px'};
    border-top-color: transparent;
    border-right-color: transparent;
    animation: ${spin} 0.6s linear infinite;
    margin: auto;
  `
};
