import { responsiveMediaQuery } from 'src/utils/mediaQueryUtils';
import styled from 'styled-components';
import Display from '../Display';

export default {
  Wrapper: styled.main`
    background-color: ${(props) => props.theme.colors.secondary};
    margin-bottom: ${(props) => props.theme.spacing.px48};
  `,
  LeftContent: styled(Display)`
    min-height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  RightContent: styled(Display)`
    background-color: ${(props) => props.theme.colors.primary};
    z-index: 1;
    margin: -${(props) => props.theme.spacing.px61} 0;
    display: flex;
    align-items: center;
    justify-content: center;

    > * {
      width: 100%;
    }

    ${responsiveMediaQuery('laptop')} {
      margin-right: 130px;
    }
  `
};
