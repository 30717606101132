import React from 'react';
import Text from '../../UI/Text';
import Layout from 'src/components/Templates/Layout';
import OnboardingStyles from './styles';
import Display from 'src/components/UI/Display';
import { FormattedMessage } from 'react-intl';
import Spacing from '../Spacing';

type OnboardingProps = {
  exportConnectHomeUri?: string;
  caExportConnectHomeUri?: string;
  googleTagManagerId?: string;
};

const Onboarding: React.FC<OnboardingProps> = ({
  children,
  exportConnectHomeUri,
  // caExportConnectHomeUri,
  googleTagManagerId
}) => {
  return (
    <Layout googleTagManagerId={googleTagManagerId}>
      <OnboardingStyles.Wrapper>
        <Display display="grid" gridTemplateColumns={2} justifyContent="center">
          <OnboardingStyles.LeftContent gridColumn={2} lg-gridColumn={1}>
            <Spacing margin="px61" lg-margin="px96" maxWidth="px450" paddingBottom="px61" lg-paddingBottom="none">
              <Text.H4 color="primary" fontSize="px24" fontWeight="bold" paddingBottom="px32" textAlign="center">
                <FormattedMessage id="onboarding.intro" />
              </Text.H4>

              <Text.Paragraph
                color="light"
                fontSize="text-base"
                paddingBottom="px4"
                textAlign="center"
                lineHeight="px24"
              >
                <FormattedMessage id="onboarding.description" />
              </Text.Paragraph>

              <Text.Paragraph
                color="light"
                fontSize="text-base"
                fontWeight="bold"
                marginTop="px42"
                paddingBottom="px32"
                textAlign="center"
              >
                <FormattedMessage id="onboarding.createAProfile" />
              </Text.Paragraph>

              <Text.H6 color="primary" fontSize="text-base" fontWeight="bold" textAlign="center">
                <FormattedMessage id="onboarding.access" />
              </Text.H6>
              <Display display="flex" flexDirection="column" alignItems="center" marginTop="px32" gapVertical="px32">
                <a href={exportConnectHomeUri} target="_blank" rel="noreferrer">
                  <img src={'/static/icons/export_connect.svg'} alt="Site logo" />
                </a>
                {/* <a href={caExportConnectHomeUri} target="_blank" rel="noreferrer">
                  <img src={'/static/icons/california_connect.svg'} alt="Site logo" />
                </a> */}
              </Display>
            </Spacing>
          </OnboardingStyles.LeftContent>

          <OnboardingStyles.RightContent gridColumn={2} lg-gridColumn={1}>
            <Spacing margin="px72" maxWidth="px450">
              {children}
            </Spacing>
          </OnboardingStyles.RightContent>
        </Display>
      </OnboardingStyles.Wrapper>
    </Layout>
  );
};

export default Onboarding;
